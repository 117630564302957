.paymentsLayer {
  position: absolute;
  top: -500px;
  height: 1280px;
  width: 100vw;
  background-color: #f9fffc;
  transform: skewY(-10deg);
  z-index: -99;
}
body[dir='rtl'] .paymentsLayer {
  transform: skewY(10deg);
}

.noqodPayments-hero {
  max-width: 500px;
}

.noqodPayments-hero > h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 20px;
}

.noqodPayments-hero > p {
  margin-bottom: 30px;
  color: #333333;
}

.noqodPayments-heroImage {
  width: 1000px;
  position: absolute;
  top: -100px;
  left: 50%;
  z-index: -10;
}

.noqodPayments-heroImage-800 {
  max-width: 80%;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
}

body[dir='rtl'] .noqodPayments-heroImage {
  right: 50%;
  top: -20px;
}

.noqodPayments-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.noqodPayments-services {
  margin-top: 300px;
}

.noqodPayments-services-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.noqodPayments-download-grid {
  padding: 20px;
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  place-items: center;
}

.noqodPayments-download-image > img {
  max-width: 100%;
}

.noqodPayments-download-detail > div {
  margin-bottom: 50px;
}

.tapToPay {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
}

.tapToPay-downloadButtons {
  margin-top: 30px;
  display: flex;
}

.tapToPay-details > h3 {
  line-height: 1.4;
  font-size: 30px;
  font-weight: 700;
}

.blueText {
  font-size: 40px;
  color: #388fe9;
}

.tapToPay-downloadButton {
  margin-inline-end: 15px;
}

.tapToPay-downloadButton img {
  max-width: 100%;
}

.tapToPay-image img {
  max-width: 100%;
}

@media screen and (max-width: 800px) {
  .noqodPayments-services-grid,
  .noqodPayments-download-grid,
  .tapToPay {
    display: grid;
    grid-template-columns: 1fr;
  }

  .noqodPayments-hero {
    margin: 45vw auto 0px auto;
  }

  .noqodPayments-heroImage-800 {
    margin-top: 40px;
    visibility: visible;
  }

  .noqodPayments-heroImage {
    display: none;
  }

  .noqodPayments-heroText {
    text-align: center;
    font-size: 28px;
  }

  .noqodPayments-hero > p {
    text-align: center;
  }

  .noqodPayments-buttons {
    display: grid;
    grid-template-columns: 1fr;
  }

  .noqodPayments-services {
    margin-top: 0px;
  }

  .tapToPay {
    place-items: center;
  }

  .tapToPay-details h3 {
    text-align: center;
  }

  .tapToPay-downloadButtons {
    margin-bottom: 40px;
    justify-content: center;
  }
}
