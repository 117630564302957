.sitemap-links {
  color: #388fe9;
  font-weight: 600;
}

.sitemap-links:hover {
  text-decoration: none;
}

/* Media Queries */
@media screen and (max-width: 678px) {
  .mobile-textcenter {
    text-align: center;
  }
}
