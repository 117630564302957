.featureShowcase,
.featureShowcase_details {
  display: flex;
  align-items: center;
}

.featureShowcase {
  width: max-content;
  background-color: white;
  padding: 15px 20px;
  border-radius: 15px;
}

.featureShowcase_details img {
  max-width: 100%;
  margin-inline-end: 20px;
}

.featureShowcase_heading {
  margin-block-end: 7px;
  font-size: 12px;
  font-weight: 500;
  color: #828282;
}

.featureShowcase_subHeading {
  font-size: 16px;
  font-weight: 800;
}

.featureShowcase_money {
  font-size: 20px;
  font-weight: 700;
}

.puppet {
  width: 40px;
}
