.DetailedLinkCard {
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  color: #333333;
}

.DetailedLinkCard > div > img {
  /* max-width: 100%; */
  margin-inline-end: 20px;
}

.heading {
  font-weight: 700;
  margin-bottom: 5px;
}

.subheading {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 1.4;
}

.DetailedLinkCard:hover {
  border-bottom: none;
  box-shadow: 0px 7px 17px rgba(199, 199, 199, 0.25);
}
