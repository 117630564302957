.footerBackground {
  background-color: #fcfffc;
  width: 100vw;
}

.footerContainer {
  padding: 50px;
  max-width: 1080px;
  margin: auto;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 10%;
}

.footerLinkSection {
  justify-self: center;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
}

.footerLinkSection > div {
  margin-left: 40px;
}

.footerText {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.4;
}

.socialLinks {
  display: flex;
  margin-top: 25px;
}

.socialLinks > div {
  margin-right: 10px;
}

.copyright {
  padding: 20px;
  margin-top: 50px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .footerContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10%;
  }
  
}
