.teamMember-container {
  padding: 20px;
  display: flex;
  align-items: center;
}

.member-avatar {
  padding-inline-end: 20px;
}

.member-detail h4 {
  font-weight: 700;
  margin-bottom: 5px;
}

.designation {
  color: grey;
  margin-bottom: 10px;
}

.bio {
  font-size: 14px;
}

.social-links {
  display: flex;
  align-items: center;
}

.social-links > div {
  padding-inline-end: 10px;
}

@media screen and (max-width: 450px) {
  .teamMember-container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .member-avatar {
    margin-bottom: 20px;
  }

  .social-links {
    justify-content: center;
  }
}
