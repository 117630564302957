.policy {
  font-size: 16px;
  max-width: 1000px;
  margin: auto;
}

.policy h1 {
}

.policy h3 {
  margin: 40px 0px 5px 0px;
}

.policy p {
  margin-bottom: 15px;
  color: #333333;
  text-align: justify;
}

.policy a {
  display: inline-block;
  padding: 10px 0px;
  margin-inline-end: 10px;
  color: #388fe9;
  text-decoration: none;
  font-weight: 600;
}

.policy a:hover {
  text-decoration: underline;
}
