.featureSection {
  max-width: 1080px;
  margin: 100px auto 100px auto;
}

.featureSection > h2 {
  max-width: 80%;
  margin: 0px auto 20px auto;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  color: #3ecf8e;
}

.featureSection > p {
  margin: 0 auto 40px auto;
  color: #272727;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  max-width: 700px;
}

.children {
  margin: auto;
}

@media screen and (max-width: 800px) {
  .featureSection > h2 {
    font-size: 30px;
  }

  .featureSection {
    margin: 100px auto -50px auto;
  }
}
