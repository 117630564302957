.step {
  display: flex;
  align-items: f;
}

.step-image {
  margin-inline-end: 20px;
}

.step-details > p {
  color: #565656;
  margin-top: 5px;
}

.step-details h3 {
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .step-details h3 {
    font-size: 24px;
  }
}
