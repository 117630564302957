.featureCard {
  overflow: hidden;
  border: 1px solid #dcdcdc;
  padding: 20px;
  min-width: 300px;
  display: flex;
  border-radius: 10px;
  align-items: center;
}

.image {
  max-width: 80px;
  margin-inline-start: -50px;
  margin-inline-end: 70px;
}

.details h4 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}

.details > p {
  font-size: 14px;
  line-height: 1.4;
}
