.layer {
  position: absolute;
  top: -500px;
  height: 1280px;
  width: 100vw;
  background-color: #f9fffc;
  transform: skewY(-10deg);
}

.one {
  height: 1440px;
  background: linear-gradient(89.96deg, #3ed08f, #014034);
  z-index: -300;
}

.two {
  height: 1360px;
  background: linear-gradient(89.96deg, #003d32, #47ffad);
  z-index: -200;
}

.three {
  z-index: -100;
}

.noqodBusiness-heroSection {
  padding: 30px;
  max-width: 800px;
  margin: -50px auto;
  text-align: center;
}

.noqodBusiness-heroSection h1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
}

.noqodBusiness-heroSection h1 span {
  font-weight: 700;
}

.heroImage {
  max-width: 100%;
}

.noqodBusiness-buttons {
  margin: 40px auto;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-content: center;
}

.noqodBusiness-grid {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 60px;
  grid-auto-flow: dense;
  transition: all 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.noqodBusiness-grid h3 {
  font-weight: 700;
}

.noqodBusiness-grid:nth-child(even) .nb-grid-image {
  grid-column: 2;
}

.noqodBusiness-grid:first-child {
  margin-top: 100px;
}

.nb-grid-image > img {
  width: 100%;
}

.nb-grid-details {
  width: 80%;
}

.nb-grid-details h3 {
  font-size: 36px;
  margin-bottom: 10px;
}

.nb-grid-details p {
  font-size: 16px;
  line-height: 1.4;
  color: #181818;
}

@media screen and (max-width: 800px) {
  .layer {
    height: 1380px;
  }

  .one {
    height: 1540px;
  }

  .two {
    height: 1460px;
  }

  .noqodBusiness-heroSection h1 {
    font-size: 28px;
  }

  .noqodBusiness-heroSection {
    margin: -100px auto;
  }

  .noqodBusiness-grid {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    text-align: center;
    margin-bottom: 50px;
  }

  .noqodBusiness-grid:nth-child(even) .nb-grid-image {
    grid-column: 1;
  }

  .noqodBusiness-buttons {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }

  .noqodBusiness-grid:first-child {
    margin-top: 50px;
  }
}
