.steps-container {
  display: flex;
}

.steps-container__step {
  margin-right: 20px;
}

body[dir='rtl'] .steps-container__step {
  margin-left: 20px;
}
