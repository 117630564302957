.list-heading {
  font-weight: 600;
  font-size: 24px;
}

.btn-apply {
  background-color: #388fe9 !important;
  outline: none;
  border: none;
}

.main-heading {
  font-weight: 600;
  font-size: 40px;
}

.text-subheading {
  font-size: 24px;
}

.text-content {
  font-size: 1.1rem;
}

body[dir='rtl'] .fix-margin {
  margin-right: 0 !important;
  margin-left: 2rem;
}

body[dir='rtl'] ul.text-content {
  margin-right: 2rem !important;
}

/* Media Queries */

@media screen and (max-width: 768px) {
  .main-heading {
    font-size: 30px;
    margin-top: -80px;
    /* text-align: center; */
  }

  .mobile-textcenter {
    text-align: center;
  }

  .mobile-center {
    justify-content: center;
  }
  .text-subheading {
    font-size: 16px;
  }
  .text-content {
    font-size: 1rem;
  }
}
