.linksHeader {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #323232;
}

.linksContainer {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: column;
  grid-column-gap: 40px;
  grid-row-gap: 10px;
}

.linksContainer a {
  color: #5b5b5b;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.listLink:hover {
  text-decoration: underline;
  color: #388fe9;
}
