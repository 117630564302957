.addressHeader {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0px;
  font-size: 15px;
}

.addressHeading {
  margin-bottom: 0px;
  padding-inline-end: 15px;
  font-weight: bold;
}

.addressHeader > p {
  margin: 0px;
}
