* {
  font-family: 'Cairo', sans-serif;
  margin: 0px;
  padding: 0px;
}

.App {
  font-family: 'Cairo', sans-serif;
  overflow-x: hidden;
}

.hero {
  max-width: 1080px;
  margin: -120px auto 100px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  place-items: center;
}

.heroText > h1 {
  font-size: 40px;
  font-weight: bolder;
  line-height: 1.4;
}

.heroText > p {
  margin-bottom: 30px;
  font-size: 20px;
  color: #505050;
  line-height: 1.4;
}

.heroBackground {
  position: absolute;
  z-index: -999;
  top: 0px;
  right: 0px;
}

.heroContainer {
  justify-self: end;
  /* margin: auto; */
  position: relative;
}

.heroContainerImage {
  max-width: 400px;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 150% 100%, 0 90%);
  border-radius: 30px 30px 0 0;
  /* transition: carousel 3s; */
}

.heroContainerText {
  overflow: hidden;
  position: absolute;
  bottom: 90px;
  left: -95px;
  width: 289px;
  height: 114px;
  border-radius: 15px;
  box-shadow: -19px 10px 66px rgba(197, 197, 197, 0.25);
  transition: all 0.1s ease;
}

.heroContainerText > img,
.heroContainerImage > img {
  animation: carousel 15s infinite;
}

body[dir='rtl'] .heroContainerImage > img,
body[dir='rtl'] .heroContainerText > img {
  animation: carouselReverse 15s infinite;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.whyNoqodGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.nProducts {
  margin-top: 150px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;
  place-items: center;
}

.nProducts_image {
  position: relative;
}

.nProducts_image > img {
  max-width: 100%;
}

.nb1 {
  position: absolute;
  top: 25%;
  left: 40%;
  transform: scale(1);
  animation: float 4s infinite;
}

.nb2 {
  position: absolute;
  bottom: 10%;
  animation: float 4s infinite reverse;
}

.np2 {
  position: absolute;
  bottom: 0px;
  animation: float 4s infinite reverse;
}

.nProducts_details {
  width: 70%;
}

.nProducts_details > h3 {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 700;
}

.nProducts_details > p {
  margin-bottom: 15px;
  line-height: 1.4;
}

.linkButton {
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-wrap: nowrap;
}

.linkButton > p {
  margin-inline-end: 15px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
  color: #46ce7c;
  transition: all 0.2s ease-in-out;
}

.linkButton:hover > p {
  margin-inline-end: 20px;
}

@keyframes carousel {
  0% {
    transform: translateX(0px);
  }
  33% {
    transform: translateX(0px);
  }
  36% {
    transform: translateX(-33.33%);
  }

  66% {
    transform: translateX(-33.33%);
  }

  70% {
    transform: translateX(-66.66%);
  }

  100% {
    transform: translateX(-66.66%);
  }
}

@keyframes carouselReverse {
  0% {
    transform: translateX(0px);
  }
  33% {
    transform: translateX(0px);
  }
  36% {
    transform: translateX(33.33%);
  }

  66% {
    transform: translateX(33.33%);
  }

  70% {
    transform: translateX(66.66%);
  }

  100% {
    transform: translateX(66.66%);
  }
}

@keyframes float {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(10px);
  }
}

@media screen and (max-width: 1050px) {
  .heroContainerText {
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 900px) {
  .hero {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
  }

  .heroContainer {
    justify-self: center;
  }

  .heroText {
    grid-row: 2;
    text-align: center;
  }

  .nb1 {
    position: absolute;
    top: 5%;
    left: 20%;
    transform: scale(1);
    animation: float 4s infinite;
  }

  .nb2 {
    position: absolute;
    bottom: 10%;
    animation: float 4s infinite reverse;
  }
}

@media screen and (max-width: 800px) {
  .heroText > h1 {
    margin-top: 20px;
    font-size: 30px;
    line-height: 1.2;
  }

  .heroContainerImage {
    max-width: 300px;
    overflow-x: hidden;
  }

  .buttons {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .linkButton {
    justify-content: center;
  }

  .whyNoqodGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .nProducts {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
  }

  .nProducts_details {
    text-align: center;
  }

  .nPayments .nProducts_image {
    grid-row: 1;
    justify-self: center;
  }
}

@media screen and (max-width: 450px) {
  .nb1 div,
  .nb2 div,
  .np2 div {
    transform: scale(0.85);
  }

  .nb1,
  .np1 {
    position: absolute;
    top: 5%;
    left: 5%;
    transform: scale(1);
    animation: float 4s infinite;
  }

  .nb2 {
    position: absolute;
    bottom: 10%;
    left: -5%;
  }
}
