.heading-text {
  font-weight: 600;
}

.career-position {
  color: #388fe9;
  font-weight: 600;
}

.career-position:hover {
  text-decoration: none;
}

tr {
  height: 60px;
}

.card-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .main-container {
    margin-top: -80px;
  }
}
