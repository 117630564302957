.header {
  max-width: 1200px;
  margin: 0px auto;
  padding: 30px 0px;
}

.header > nav {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}

.linkList {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkList > li {
  /* padding: 0 30px; */
  color: black;
  text-decoration: none;
  list-style-type: none;
}

.navLink {
  padding: 30px 30px;
  text-decoration: none;
  color: black;
  font-weight: 600;
}

.language > select {
  border: none;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0) !important;
}

.subMenuCards {
  position: absolute;
  transform: translateX(-40%);
  transition: all 0.2s ease;
}

.mobileMenuToggle {
  display: none;
}

.logoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileNav-container {
  display: none;
}

.contact-contour {
  padding: 20px;
  margin-top: -40px;
}

@media screen and (max-width: 800px) {
  .mobileNav-container {
    display: block;
    visibility: hidden;
    position: absolute;
    z-index: 1000;
    top: 0px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 5vw;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.281);
    transition: visibility 0.3s ease;
  }

  .mobile-nav {
    background-color: white;
    border-radius: 15px;
  }

  .mobileNav-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logoContainer {
    width: 100vw;
    padding: 20px;
  }

  .mobileMenuToggle {
    display: block;
  }

  .mobileNav-header > .mobileMenuToggle {
    margin-right: 0px;
  }

  .linkList,
  .language {
    display: none;
  }

  .header > nav {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
  }
}
