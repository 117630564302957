.branding-header {
  /* margin-bottom: px; */
  padding-bottom: 30px;
}

.branding-header > h1 {
  font-size: 44px;
  font-weight: 700;
}

.branding-header p a {
  font-weight: 600;
}

.our-logo {
  margin-bottom: 50px;
  padding-top: 30px;
  border-top: 1px solid #959595;
}

.our-logo h3 {
  font-size: 24px;
  font-weight: 700;
}

.logo-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 50px;
  background-color: #fcfffc;
  border-radius: 15px;
  border: 1px dashed #8a8a8a;
}

.white-logo {
  background-color: #333333;
}

.logo-full .logo {
  padding: 40px;
}

.download-links {
  align-self: flex-end;
}

.download-links a {
  padding: 10px;
}

.colors {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

@media screen and (max-width: 800px) {
  .branding-header {
    margin-top: -100px;
  }

  .logo img {
    max-width: 100%;
  }

  .colors {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .colors {
    grid-template-columns: 1fr;
  }
}
