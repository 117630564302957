.LinkCard {
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  color: #333333;
}

.LinkCard > img {
  max-width: 100%;
  margin-inline-end: 20px;
}

.LinkCard > p {
  font-weight: 500;
  margin-bottom: 0px;
}

.LinkCard:hover {
  border-bottom: none;
  box-shadow: 0px 7px 17px rgba(199, 199, 199, 0.25);
}
