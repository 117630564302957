.aboutUs-heading {
  max-width: 55%;
  font-size: 36px;
  font-weight: 700;
}

.aboutUs-hero {
  position: relative;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.introduction_section {
  max-width: 100%;
  margin: 100px auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 50px 30px;
  background-image: url('/assets/image_introduction.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  /* box-shadow: 0px 0px 5px 2px #cacaca; */
  box-shadow: 0px 4px 10px #cecece;
}

.introduction {
  border-radius: 15px;
  background-color: white;
  max-width: 450px;
  padding: 30px;
  right: 20px;
}

.aboutUs_headings {
  font-size: 35px;
  margin-bottom: 5px;
  font-weight: 700;
}

.knowUsSection {
  margin: 120px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
  align-items: center;
}

.knowUs-image {
  width: 100%;
}

.noqodTeam {
  padding: 50px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.joinTheTeam > h2 {
  margin: 30px auto;
  max-width: 600px;
  font-size: 40px;
  text-align: center;
}

.joinTheTeam a {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 10px 0px;
  border-radius: 10px;
  background-color: #388fe9;
  box-shadow: 0px 4px 15px #cecece;
  color: white;
  text-decoration: none;
}

.joinTheTeam a img {
  margin-left: 10px;
}

.joinTheTeam a:hover {
  box-shadow: none;
}

@media screen and (max-width: 800px) {
  .aboutUs-heading {
    margin-top: -100px;
  }

  .introduction_section {
    margin: 50px auto;
  }

  .knowUsSection {
    margin: 120px auto;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
  }

  .knowUs {
    max-width: 90%;
    padding: 0px;
    margin-top: 60px;
    text-align: center;
  }

  .noqodTeam {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .aboutUs-hero {
    position: relative;
    width: 100%;
    bottom: 0px;
    top: 0px;
    left: 50%;
  }
}

@media screen and (max-width: 650px) {
  .aboutUs-heading {
    max-width: 100%;
    text-align: center;
    font-size: 30px;
    margin: -100px auto 50px auto;
  }

  .joinTheTeam > h2 {
    font-size: 30px;
    text-align: center;
  }
}

/* RTL Design */

body[dir='rtl'] .aboutUs-hero {
  position: relative;
  bottom: 60px;
  right: 50%;
  transform: translateX(50%);
  /* left: 50%;
  transform: translateX(-50%); */
}
