.subLinks {
  /* margin-top: 10p; */
  background-color: white;
  width: 550px;
  border-radius: 7px;
  border: 1px solid #dedede;
  box-shadow: 0px 35px 51px rgba(215, 215, 215, 0.25);
}

.subLink {
  padding: 20px;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
}

.subLinks_header {
  font-size: 22px;
  border-radius: 7px 7px 0 0;
  padding: 20px;
  background-color: #f6fff6;
}

@media screen and (max-width: 800px) {
  .subLinks {
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    border-radius: 0px;
    border: none;
    box-shadow: none;
  }
  .subLink {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}
