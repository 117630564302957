.button,
.button_primary {
  padding: 20px 20px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;
  background-color: white;
  transition: all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.button {
  border: 1px solid #a7a7a7;
}

.button .buttonLink {
  font-size: 20px;
  color: grey;
  text-decoration: none;
}

.button_primary .buttonLink {
  color: white;
  font-size: 20px;
  text-decoration: none;
}

.button:hover,
.button_primary:hover {
  transform: scale(1.03);
  box-shadow: 0px 6px 24px #e9e9e9;
}
