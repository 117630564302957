.noqodUpshots {
  width: 500px;
  background-color: white;
  border-radius: 7px;
  border: 1px solid #dedede;
  box-shadow: 0px 35px 51px rgba(215, 215, 215, 0.25);
}

.subLink {
  padding: 20px;
}

@media screen and (max-width: 800px) {
  .noqodUpshots {
    width: 100%;
    border-radius: 0px;
    border: none;
    box-shadow: none;
  }
}
