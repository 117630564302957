.heading-text {
  font-weight: 600;
}

.heading-color {
  color: #7002b9;
  font-weight: 700;
}

.gateway-layer {
  position: absolute;
  top: -500px;
  height: 1500px;
  width: 100vw;
  background-color: #f7ebff;
  /* clip-path: polygon(0 0%, 100% 0, 100% 90%, 0% 100%); */
  transform: skewY(-10deg);
  z-index: -99;
}

.gateway-buttons {
  margin: 40px auto;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-content: center;
}
.gateway-layer-bottom {
  position: absolute;
  bottom: 410px;
  left: 0;
  width: 100vw;
  height: 860px;
  background-color: #f7ebff;
  z-index: -99;
}

.documentation_btn {
  margin-top: 20px;
  width: 50%;
}

.feature-container {
  margin-top: -60px;
  margin-bottom: 120px;
}

@media screen and (max-width: 800px) {
  /* .gateway-layer {
    top: -450px;
    height: 1500px;
    transform: skewY(-10deg);
  } */

  .gateway-layer-bottom {
    bottom: 410px;
    left: 0;
    height: 860px;
  }

  .gateway-buttons {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
  }

  .documentation_btn {
    width: 100%;
    margin-bottom: 20px;
  }

  .feature-container {
    margin-top: -60px;
    margin-bottom: 180px;
  }
}

@media screen and (max-width: 768px) {
  .gateway-layer-bottom {
    /* height: 900px; */
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .gateway-layer-bottom {
    height: 1420px;
  }

  body[dir='rtl'] .gateway-layer {
    height: 1420px;
  }
}

@media screen and (max-width: 558px) {
  .gateway-layer-bottom {
    height: 1580px;
  }
}

@media screen and (max-width: 500px) {
  .gateway-layer-bottom {
    height: 1520px;
  }
  body[dir='rtl'] .gateway-layer {
    height: 1380px;
  }
}

@media screen and (max-width: 395px) {
  .gateway-layer {
    top: -450px;
    height: 1520px;
    transform: skewY(-10deg);
  }

  .documentation_btn {
    display: none;
  }

  .gateway-layer-bottom {
    height: 1520px;
  }

  body[dir='rtl'] .gateway-layer {
    height: 1360px;
  }

  body[dir='rtl'] .gateway-layer-bottom {
    height: 1360px;
  }
}
