.form-input {
  border-radius: 8px !important;
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.form-label {
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

.form-input-msg {
  height: 140px !important;
}

/* Map */
.mapouter {
  position: relative;
  text-align: right;
  height: 550px;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 550px;
  width: 100%;
}

.contact-address {
  color: #828282;
  font-size: 20px;
  font-weight: 700;
}

.address {
  color: #3f3f3f;
  font-weight: 600;
  margin-bottom: 0px;
}

.address-container {
  background-color: #f9fffc;
  height: 100%;
  width: 100%;
  margin-left: -16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.btn-submit {
  background-color: #46ce7c !important;
  border: none !important;
}

.heading-text {
  font-weight: 700;
}

.contact-img {
  width: 100%;
}

.captcha {
  justify-content: flex-end;
}

body[dir='rtl'] .flip-arrow {
  transform: scaleX(-1);
}

/* Media queries */
@media screen and (max-width: 768px) {
  .contact-img {
    display: none;
  }

  .btn-submit {
    width: 100%;
  }

  .address-container {
    margin-left: 16px;
    margin-top: 20px;
    /* height: 400px; */
  }

  .main-container {
    margin-top: -80px;
  }

  .captcha {
    justify-content: center;
    width: 100%;
    transform: scale(0.7);
  }
}
