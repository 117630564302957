.color-palette {
  padding: 20px;
  border-radius: 15px;
  color: white;
}

.color-palette h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 100px;
}

.color-palette p {
  margin-bottom: 0px;
}
